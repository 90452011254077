import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const Manna = () => (
  <Layout>
    <SEO
      title="Talking Natural Hair with Manna"
      description="We talk natural hair with the beautiful model Manna. We picked up some tips and learn about some new products. Can’t wait for you to read!"
    />
    <h1>Talking Natural Hair with Manna</h1>
    <h2 style={{color:"Orchid"}}>Life as an Musician and Natural hair Fashion Model</h2>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/3WO5xUs.jpg"
        alt="Manna natural afro hair care"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Manna McLeod</p>
    </div>

    <h3>Hello Manna, tell us a bit about yourself?</h3>
    <p>I’m Manna, I am 21 years of age and I was born in London but sort of live in the countryside now! I’m an artist, I sing-song write and I’m a full time model that really loves creating in almost every way.</p>

    <h3>What does your natural hair mean to you?</h3>
    <p> I have always been very proud of my hair ever since I was a little girl, all my childhood I had crazily long thick hair that would actually weigh my neck down because I have such a small head. It used to be something I considered as my crown and proof of how beautiful I am, now in some ways I feel the same to a degree but for the most part my hair is now an expression of my personality, I look after it as if it’s my baby because I really do value the health of it, so my hair for me means a lot!</p>

    <h3>What is your family history?</h3>
    <p>I’m a big mix of things - Jamaican, Lebanese, British and in recent years found out I actually have a maroon town in Jamaica named after my family - that would explain the thick, full body of my hair!</p>

    <h3>Which hair products do you use?</h3>
    <p>Currently I love Ettenio hair products, a Jamaican company that uses all natural products for all Afro hair types, it is amazing. It sits with my hair using a reasonable amount compared to European brands I used the majority of my life and would use half a tub of product in one sitting!</p>

    <h3>Talk us through your hair routine</h3>
    <p>I wash my hair anywhere between 3-5 times a month using a non-sulphate shampoo and steam it with Ettenio’s hair mask, it smells just like chocolate espresso and I love that! I steam for a good while too like a whole 24hours, I will wrap it and then wrap a scarf over the wrap and go about my day. Once I rinse, I moisturise with coconut oil and castor oil (it’s a must) and then I use a leave in conditioner depending on how moisturised my hair is already feeling. Then I twist it up and leave that under a scarf until I want my big Afro out! Growing up I used to bang twists and I still love them so once my hair is at a length that sits well with me I will be doing that again!</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/S7mKZKy.jpg"
        alt="Manna McLeod Natural Afro Hair Care"
      />
    </div>

    <h3>How did you view your hair growing up and were other people interested in your hair?</h3>
    <p>Growing up I knew my hair was amazing, everybody would comment on how long and nice it was - I would break combs during my hair appointments and I mean some sturdy combs, some hairdressers would complain, some would adore it - for the most part people always had something positive to say about it but also people would behave as though it was abnormal that my hair was that long because I’m black LOL, it was crazy to me! I would wonder why I couldn’t wear my hair out and big all the time then I understood it would just become too much to manage (pain for me having my hair combed and pain for my mum doing the combing!).</p>

    <h3>Did you have a role model growing up that helped you?</h3>
    <p>Growing up my Mum really and truly was my role model, she changed her hair all the time, I mean weekly, one week she’d be blonde, the next a full shaven head, following that she had this one hairstyle, a Black Afro with a thick red stripe down one side of her hair and I remember just thinking my Mummy is so beautiful and crazy I want to be able to be so brave! She really had so much patience with my hair but there was only so much crying and pain the both of us could deal with when it came to the weekend and it was time for her to do my hair.</p>

    <h3>Do you have any interesting stories?</h3>
    <p>I had my hair relaxed for the first time at 3 years old because it was just too much (I hope I can find some images to show you guys) it just helped us to manage it easier - I had relaxer put in and grew it out I think around 5/6 times in my life and each time we cut it it’d grow back like it was never relaxed in the first place... hair on steroids!</p>

    <h3>What happened when you started doing your hair yourself?</h3>
    <p>Once I started looking after my hair I pretty much messed it up 3 times with heat damage and on one occasion I developed a psychological condition called trichotillomania which is where you essentially rip your hair out from stress as a comforting thing, as self punishment or as a habit you’ve developed and “can’t resist”. So my hair in my adult life so far as been cut off and grown back 3 times!</p>

    <h3>What advice would you give for taking care of natural hair?</h3>
    <p>At first doing my hair myself was overbearing but once I learned how beautiful my hair is again and appreciated how important it is to keep it healthy - if my hair has split ends they have to go - I mean I don’t even use heat on it, I’d sooner cut it all off than hang on to any form of unhealthy hair.</p>

    <h3>What is your favourite hairstyle right now?</h3>
    <p>I switch up my hair quite a lot, I wear wigs sometimes when I want my hair to flip around and be extra with it. My favourite hairstyle is my big kinky Afro, it is actually a huge reflection of me and my hair is my favourite hair in the world. I get braids every so often, mainly canerows that go straight back I think there’s something so simple and sexy about them and of course twists or box braids are always fun and beautiful too.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/X2pw9iD.jpg"
        alt="Manna Braids natural afro hair care"
      />
    </div>

    <h3>Anything you wish you knew sooner?</h3>
    <p>I wish I knew that having Afro hair and finding GOOD products for it isn’t simple - that way I’d have found Ettenio sooner and my hair would be 10xs the length already!</p>
    <p>I will tell anybody on their natural hair journey to love it, love your hair - this means cut it when it’s not healthy, treat it and be patient with it! It’s beautiful when you let it be!</p>

    <h3>Do you have any hair plans for the future?</h3>
    <p>I am currently growing my hair out, the tapered Afro I rock is so much fun and easy for me because I literally wake up shake my hair and leave the house but it’s time to grow it all back and get these Manna locs back on the go!</p>

    <h3>What are you passionate about and can you tell us about any projects you are working on?</h3>
    <p>I am currently in the process of spending time in Jamaica creating music and I would love to film more about my daily life whilst I am documenting the musical aspect of my life, I get a lot of questions about my hair and skin so I am considering discovering new natural products for both hair and skin for myself, followers and everybody interested so watch out for that!</p>

    <h3>How can we follow/ contact you?</h3>
    <p>Follow me on Instagram <a
        href="https://www.instagram.com/mcleodmanna/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      @mcleodmanna
      </a></p>



    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}





      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/talking-natural-hair-with-ebonie-roberge/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            Travel The World and Natural Hair with Ebonie Roberge
            </Link>{" "}
            - We talk natural hair with the beautiful Ebonie Roberge. We learned that the journey to a perfect natural hair routine can be a long one...
          </p>
          <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>          
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default Manna
